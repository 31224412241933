import GamingLibrary_01 from "../assets/images/game-01.jpg";
import GamingLibrary_02 from "../assets/images/game-02.jpg";
import GamingLibrary_03 from "../assets/images/game-03.jpg";

const gamingLibraryData = [
  {
    id: "Library-item-1",
    Image: GamingLibrary_01,
    title: "Fortnite",
    category: "SandBox",
    date_added: "20/15/2035",
    hours_played: "20h",
    currently: 'downloaded'
  },
  {
    id: "Library-item-2",
    Image: GamingLibrary_02,
    title: "PubG",
    category: "Stream-X",
    date_added: "2/11/2000",
    hours_played: "20h",
    currently: 'downloaded'
  },
  {
    id: "Library-item-3",
    Image: GamingLibrary_03,
    title: "Dat02",
    category: "Battle-S",
    date_added: "20/1/2015",
    hours_played: "20h",
    currently: 'downloaded'
  },
];

export default gamingLibraryData;

import "./Card.css";
import { FaStar, FaMarkdown } from "react-icons/fa";


const Card = ({ image, title, category, rate, downloaded }) => {
  return (
    <div className="most-popular-item">
      <div className="card-wrapper">
        <img className="most-popular-item-image" src={image} alt="01" />
        <div className="most-popular-item-content">
          <h4 className="most-popular-item-title">
            {title} <br />
            <span>{category}</span>
          </h4>
          <ul>
            <li>
              <span style={{color: 'yellow'}}><FaStar /> </span><span>{rate}</span>
            </li>
            <li>
              <FaMarkdown /> <span>{downloaded}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Card;

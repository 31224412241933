import popular_01 from "../assets/images/popular-01.jpg";
import popular_02 from "../assets/images/popular-02.jpg";
import popular_03 from "../assets/images/popular-03.jpg";
import popular_04 from "../assets/images/popular-04.jpg";
import popular_05 from "../assets/images/popular-05.jpg";
import popular_06 from "../assets/images/popular-06.jpg";
import popular_07 from "../assets/images/popular-07.jpg";
import popular_08 from "../assets/images/popular-08.jpg";

const mostPopularData = [
  {
    id: "most_popular_item_0",
    title: "Fortnite",
    Image: popular_01,
    category: "SandBox",
    rate: "3.5",
    downloaded: "1.2M",
  },
  {
    id: "most_popular_item_1",
    title: "PubG",
    Image: popular_02,
    category: "Stream-X",
    rate: "4.5",
    downloaded: "2.2M",
  },
  {
    id: "most_popular_item_3",
    title: "Dat02",
    Image: popular_03,
    category: "Battle-S",
    rate: "2.5",
    downloaded: "4.2M",
  },
  {
    id: "most_popular_item_4",
    title: "Fortnite",
    Image: popular_04,
    category: "SandBox",
    rate: "1.5",
    downloaded: "6.2M",
  },
  {
    id: "most_popular_item_5",
    title: "Fortnite",
    Image: popular_05,
    category: "SandBox",
    rate: "4.9",
    downloaded: "8.2M",
  },
  {
    id: "most_popular_item_6",
    title: "Fortnite",
    Image: popular_06,
    category: "SandBox",
    rate: "4.4",
    downloaded: "9M",
  },
  {
    id: "most_popular_item_7",
    title: "Fortnite",
    Image: popular_07,
    category: "SandBox",
    rate: "3.5",
    downloaded: "12M",
  },
  {
    id: "most_popular_item_8",
    title: "Fortnite",
    Image: popular_08,
    category: "SandBox",
    rate: "1.8",
    downloaded: "7.2M",
  },
];

export default mostPopularData;

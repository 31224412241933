import {
  GamingLibraryCard,
  SectionHeader,
  SectionWrapper,
} from "../../Components";
import gamingLibraryData from "../../Data/GamingLibraryData";
import "./GamingLibrary.css";

const GamingLibrary = () => {
    const cards = gamingLibraryData.map((card) => {
    return (
      <GamingLibraryCard
        key={card.id}
        image={card.Image}
        title={card.title}
        category={card.category}
        date_added={card.date_added}
        hours_played={card.hours_played}
        currently={card.currently}
      />
    );
  });

  return (
    <div>
      <SectionWrapper>
        <SectionHeader>Gaming Library</SectionHeader>
        <div className="gaming-library-cards">{cards}</div>
      </SectionWrapper>
    </div>
  );
};

export default GamingLibrary;

import "./Header.css";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import NavItem from "../../Components/NavItem/NavItem";

const Header = () => {
  return (
    <>
      <div className="active navbar navbar-expand-md  navbar-dark text-white">
        <div className="container">
          <Link to="/" className="navbar-brand">
            <img src={logo} alt="logo" className="image" />
          </Link>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#mainmenu">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="mainmenu">
            <ul className="navbar-nav ms-auto">
              <NavItem>
                <Link to="/" className="nav-link">
                  Home
                </Link>
              </NavItem>







              <NavItem>
                <Link to="/Profile" className="nav-link">
                  Profile
                </Link>
              </NavItem>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;

import "./Home.css";
import { GamingLibrary, Hero, MostPopular } from "../../sections";

const Home = () => {
  return (
    <>
      <h1 style={{ color: "white", fontSize:'20px' }}>Home Page</h1>
      <Hero />
      <MostPopular />
      <GamingLibrary />
    </>
  );
};

export default Home;

import { MostPopular } from "../../sections";
import "./Profile.css";

const Profile = () => {
  return (
    <>
      <h1 style={{ color: "white", fontSize:'20px' }}>Profile Page</h1>
      <MostPopular />
    </>
  );
};

export default Profile;

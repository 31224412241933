import "./Footer.css";

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <p>
              Copyright 2036 <a href="#/">Cyborg Gaming</a> Company. All rights
              reserved. <br />
              Design:{" "}
              <a
                href="https://templatemo.com"
                target="blank"
                title="free CSS templates">
                Templates
              </a>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
